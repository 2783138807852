import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { deleteTeam } from "../../hooks/teamDelete.js";
import "./style.css";

const TeamDeleteModal = ({
  show,
  onHide,
  teamName,
  teamId,
  getOrgInfo,
  updateMessage,
}) => {
  const handleSubmit = () => {
    deleteTeam({ team_id: teamId })
      .then((response) => {
        // Close the modal
        onHide();
        getOrgInfo();
        updateMessage(
          "success",
          "Success! The Team has been Deleted successfully"
        );
      })
      .catch((error) => {
        updateMessage("error", `[${error.message}] Failed to delete team!`);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Are you sure you want to delete the team "{teamName}"?</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamDeleteModal;
