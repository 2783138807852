import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Dropdown } from "react-bootstrap";

import { editData } from "../../hooks/dataEdit.js";
import "./style.css";
import DatePicker from "react-datepicker";
import { CiCalendar } from "react-icons/ci";
import moment from "moment-timezone";

const DataEditModal = ({
  show,
  onHide,
  dataInfo,
  formId,
  template,
  getFormInfo,
  updateMessage,
}) => {
  const [data, setData] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [dependentFieldMap, setDependentFieldMap] = useState(null);
  const [masterFeildName, setMasterFeildName] = useState(null);
  const [errors, setErrors] = useState({});
  const [enabledDailyFlow, setEnabledDailyFlow] = useState(null);
  const [otherResetReason, setOtherResetReason] = useState("");
  const [isOverrideChecked, setIsOverrideChecked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(null);

  // useEffect(() => {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //                 setUserPosition(`(${position.coords.latitude}, ${position.coords.longitude})`)
  //             },
  //             (error) => {
  //                 console.log("Geolocation is not available")
  //             }
  //         )
  //     }
  // }, [])

  useEffect(() => {
    template?.map((field) => {
      if (field.type === "master_choice") {
        setMasterFeildName(field.name);
        setDependentFieldMap(field.dependent_values);
      }
    });
    // use copy of info
    setOtherResetReason(null);
    let dataInfoCopy = { ...dataInfo };
    if (
      dataInfoCopy["Override Reason"] !== "Meter Reset" &&
      dataInfoCopy["Override Reason"] !== "Meter Changed"
    ) {
      if (dataInfoCopy["Override Reason"]) {
        setOtherResetReason(dataInfoCopy["Override Reason"]);
      }
      dataInfoCopy["Override Reason"] = "Other - Please Specify";
    }
    setIsOverrideChecked(dataInfoCopy["Please Check to override"]);
    setData({ ...dataInfoCopy });

    // if (
    //   dataInfo?.["Daily Flow (M3)"] < 0 ||
    //   dataInfo?.["Daily Power (KW)"] < 0
    // ) {
    //   setEnabledDailyFlow(() => true);
    // }
  }, [dataInfo, template]);

  const handleSubmit = (isChecked = true) => {
    const newErrors = {};
    template.forEach((field) => {
      if (field.required && !data[field.name]) {
        newErrors[field.name] = `${field.name} is required`;
      }

      if (field.name === "Override Value" && data["Please Check to override"]) {
        if (!data[field.name]) {
          newErrors[field.name] = `${field.name} is required`;
        }
      }
      // Conditionally validate the "Override Reason" field
      if (
        field.name === "Override Reason" &&
        data["Please Check to override"]
      ) {
        if (!data[field.name]) {
          newErrors[field.name] = `${field.name} is required`;
        }
        if (
          data[field.name] === "Other - Please Specify" &&
          !otherResetReason
        ) {
          newErrors["Other Reason"] = "Other Reason is required";
        }
      }

      // if (
      //   (field.name === "Daily Flow (M3)" ||
      //     field.name === "Daily Power (KW)") &&
      //   field.type === "calculated" &&
      //   data[field.name] < 0
      // ) {
      //   newErrors[field.name] = `${field.name} can't be negative`;
      // }

      // Trim spaces and validate camelCase format for "reason for failure" field
      if (field.name === "Reason for Failure" && data[field.name]) {
        // Trim spaces from start and end
        data[field.name] = data[field.name].trim();
      }

      if (field.type === "time" && !data[field.name]) {
        data[field.name] = undefined;
      }

      if (
        (field.type === "datetime" || field.type === "date") &&
        data[field.name]
      ) {
        if (field.type === "datetime") {
          data[field.name] = moment(data[field.name]).format(
            "YYYY-MM-DDTHH:mm"
          );
        }
        if (field.type === "date") {
          data[field.name] = moment(data[field.name]).format("YYYY-MM-DD");
        }
        // data[field.name] = moment(data[field.name]).format("YYYY-MM-DDTHH:mm")
        // Check if the date is in the future
        if (new Date(data[field.name]) > new Date()) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        }

        // Check if the date is at least 2 years in the past
        const diffInMilliseconds = Math.abs(
          new Date(data[field.name]) - new Date()
        );
        const diffInDays = Math.floor(
          diffInMilliseconds / (1000 * 60 * 60 * 24)
        );
        const year = moment(data[field.name]).year(); // Extract the year from the date

        // If the time is in future, show error
        if (year < 1000 || year > 9999) {
          newErrors[field.name] = `${field.name} is not a valid year`;
        } else if (
          moment(data[field.name]).isValid() &&
          moment(data[field.name]).isAfter(moment())
        ) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        }
        // else if (diffInDays > 365 * 2) {
        //   newErrors[
        //     field.name
        //   ] = `${field.name} must be at least 2 years in the past`;
        // }
      }
    });

    // List of date fields to validate
    const dateFields = [
      "Time of sample collection",
      "Time of lab test",
      "Start Time",
      "End Time",
      "Shutdown Datetime",
      "Resolved Datetime",
    ];

    // Helper function to validate dates and check order
    const isValidDate = (date) => moment(date).isValid();
    const isSameDate = (date1, date2) => date1.toString() === date2.toString();
    const addError = (field, message) => {
      newErrors[field] = message;
    };

    // Validate that all date fields are in a valid format
    dateFields.forEach((field) => {
      if (data[field] && !isValidDate(data[field])) {
        addError(field, `${field} is not a valid date`);
      }
    });

    // Validate date order only if both dates are valid
    const validateDateOrder = (startField, endField, startLabel, endLabel) => {
      if (!newErrors[startField] && !newErrors[endField]) {
        const startDate = new Date(data[startField]);
        const endDate = new Date(data[endField]);

        if (endDate < startDate) {
          addError(startField, `${startLabel} must be before ${endLabel}`);
        } else if (isSameDate(startDate, endDate)) {
          addError(
            startField,
            `${startLabel} and ${endLabel} cannot be the same`
          );
          addError(
            endField,
            `${endLabel} and ${startLabel} cannot be the same`
          );
        }
      }
    };

    // Perform date order validations only if initial format checks passed
    if (data["Time of sample collection"] && data["Time of lab test"]) {
      validateDateOrder(
        "Time of sample collection",
        "Time of lab test",
        "Time of sample collection",
        "Time of lab test"
      );
    }

    if (data["Start Time"] && data["End Time"]) {
      validateDateOrder("Start Time", "End Time", "Start Time", "End Time");
    }

    if (data["Shutdown Datetime"] && data["Resolved Datetime"]) {
      validateDateOrder(
        "Shutdown Datetime",
        "Resolved Datetime",
        "Shutdown Datetime",
        "Resolved Datetime"
      );
    }

    let newData = { ...data };
    if (data["Shutdown Datetime"] && !data["Resolved Datetime"]) {
      newData = { ...data, "Resolved Datetime": "" };
    }
    if (!isChecked) {
      newData = { ...data, isCheck: false };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("called 0");
      return;
    }

    if (data["Override Reason"] === "Other - Please Specify") {
      newData = { ...newData, "Override Reason": otherResetReason };
    }

    if (data["Please Check to override"] === false) {
      delete newData["Override Reason"];
      delete newData["Override Value"];
    }
    setIsOverrideChecked(data["Please Check to override"]);

    editData(newData, formId, dataInfo.id)
      .then((response) => {
        setShowConfirmation(false);
        if (response && response.isThreshold) {
          setShowConfirmation(true);
          setConfirmMessage(response.message);
        } else {
          setErrors({});
          // Close the modal
          onHide();
          getFormInfo();
          if (response && response.isWarning) {
            if (response.message && response.message.length > 0) {
              const messageList = `<ul>${response.message
                .map((mess) => `<li>${mess}</li><br>`)
                .join("")}</ul>`;
              updateMessage("warning", messageList, {
                html: true,
                requireConfirmation: true,
              });
            }
          } else {
            updateMessage("success", response.message);
          }
        }
        // updateMessage(
        //   response.message.startsWith("It looks like your meter may have reset")
        //     ? "warning"
        //     : "success",
        //   response.message
        // );
        setEnabledDailyFlow(false);
      })
      .catch((error) => {
        updateMessage("error", error.message);
      });
  };

  const handleConfirmation = () => {
    handleSubmit(false);
  };
  const handleUpdate = (e, field, type) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      if (field === "Reset Reason") {
        delete newErrors["Other Reason"];
      }
      return newErrors;
    });
    let value = e.target.value;
    if (type === "checkbox") {
      value = e.target.checked;
      if (field === "Please Check to override") {
        setIsOverrideChecked(value);
      }
    }

    if (type == "datetime") {
      const value = e.target.value;

      // Check if the input is in the format of `YYYY-MM-DDTHH:mm`
      const yearPart = value.split("-")[0];
      if (yearPart.length > 4) {
        // Trim the year to 4 digits if it exceeds 4 digits
        const trimmedValue = value.replace(/^(\d{4})\d*/, "$1");
        e.target.value = trimmedValue;
      }
    }

    setData((prevValues) => {
      // Check if the updated field is the masterField
      if (field === masterFeildName) {
        const newValues = dependentFieldMap[e.target.value];
        return {
          ...prevValues,
          ...newValues,
          [field]: e.target.value,
        };
      }
      // If not the masterField, just update the specific field
      if (field === "Please Check to override") {
        return {
          ...prevValues,
          [field]: e.target.checked,
        };
      }
      return {
        ...prevValues,
        [field]: e.target.value,
      };
    });
  };

  const handlePosition = (field) => {
    if (userPosition) {
      setData((prevValues) => {
        return { ...prevValues, [field]: userPosition };
      });
    } else {
      console.log("Geolocation is not available");
    }
  };

  const handleCheckboxChange = (event, choice, field_name) => {
    setIsOverrideChecked(event.target.checked);
    const selectedOptions = data?.[field_name] || [];

    if (choice == "Other - Please Specify" && event.target.checked) {
      setData((prevState) => ({
        ...prevState,
        [field_name]: [choice],
      }));
      return;
    }

    if (event.target.checked) {
      // Add the checked value to the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: [...selectedOptions, choice],
      }));
    } else {
      // Remove the unchecked value from the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: selectedOptions.filter((option) => option !== choice),
      }));
    }
  };

  // render each field by type
  const renderFields = (field) => {
    if (data) {
      switch (field.type) {
        case "datetime":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld">
              <Form.Control
                type="datetime-local"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
                format="YYYY-MM-DDThh:mm" // Format to yyyy-mm-ddThh:mm
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              {/* <DatePicker
                selected={data[field.name] ? new Date(data[field.name]) : null}
                placeholderText="YYYY-MM-DD, HH:mm"
                onChange={(e) => {
                  console.log('e==========+>', e)
                  e = {
                    target: {
                      value: e
                    }
                  }
                  handleUpdate(e, field.name, field.type)
                }}
                className="form-control date-picker-custom"
                dateFormat="yyyy-MM-dd, HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                required={field.required}
                // value={data[field.name]}
                icon={<CiCalendar />}
              /> */}
              </div>
            </Form.Group>
          );

        case "decimal":
          if (field.name === "Override Value") {
            return data["Please Check to override"] ? (
              <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {isOverrideChecked && <span style={{ color: "red" }}>*</span>}
                </Form.Label>

                <div className="txx-fld">
                <Form.Control
                  type="number"
                  step="0.01"
                  value={data[field.name]}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^\d{0,10}(\.\d{0,2})?$/;
                    if (regex.test(input) || input === "") {
                      handleUpdate(e, field.name, field.type); // Only update valid values
                    }
                  }}
                  onKeyDown={(e) => {
                    if (["-", "e", "E", "+"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onInput={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  required={isOverrideChecked}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
                </div>
              </Form.Group>
            ) : null;
          } else {
            return (
              <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>

                <div className="txx-fld">
                <Form.Control
                  type="number"
                  step="0.01"
                  value={data[field.name]}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^-?\d{0,10}(\.\d{0,2})?$/;
                    if (regex.test(input) || input === "") {
                      handleUpdate(e, field.name, field.type); // Only update valid values
                    }
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+"].includes(e.key)) {
                      e.preventDefault();
                    }
                    if (field.name !== "Dew Point (C)" && e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
                </div>
              </Form.Group>
            );
          }
        case "checkbox":
          return (
            <Form.Group
              key={field.name}
              controlId={field.name}
              style={{ marginBlock: "7px" }}
              className="modal-fld"
            >
            <div className="txx-fld master-chioce-box">
              <Form.Check
                type="checkbox"
                label={field.name}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                // value={data[field.name]}
                checked={data[field.name]}
              />
              </div>
            </Form.Group>
          );
        case "calculated":
          if (field.name === "Running Average") {
            // Replace "YourFieldName" with the actual field name
            return (
              <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                <Form.Label>{field.name}</Form.Label>
                <div className="txx-fld">
                <Form.Control
                  type="number"
                  value={data[field.name]}
                  disabled={true}
                />
                </div>
              </Form.Group>
            );
          }
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld">
              <Form.Control
                type="number"
                step="0.01"
                disabled={!enabledDailyFlow}
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "date":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

               <div className="txx-fld">
              <Form.Control
                type="date"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
                format="YYYY-MM-DD" // Format to yyyy-mm-dd
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "choice":
          if (field.name === "Override Reason") {
            return data["Please Check to override"] ? (
              <div>
                <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                  <Form.Label>
                    {field.name}
                    {isOverrideChecked && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </Form.Label>
                  <div className="txx-fld as-select-input">
                  <Form.Control
                    as="select"
                    value={data[field.name]}
                    onChange={(e) => handleUpdate(e, field.name, field.type)}
                    required={isOverrideChecked}
                    isInvalid={!!errors[field.name]}
                  >
                    {field.choices?.map((choice, index) => (
                      <option key={index} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                  </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <div
                  style={{
                    marginBlock: "7px",
                  }}
                >
                  {data[field.name] === "Other - Please Specify" && (
                    <div>
                      <Form.Group className="modal-fld">
                      <Form.Label>
                        If other , Please Specify
                        {isOverrideChecked && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </Form.Label>
                      <div className="txx-fld">
                      <Form.Control
                        value={otherResetReason}
                        onChange={(e) => {
                          setOtherResetReason(e.target.value);
                          handleUpdate(e, "Reset Reason", "text");
                        }}
                        required={isOverrideChecked}
                        isInvalid={!!errors["Other Reason"]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["Other Reason"]}
                      </Form.Control.Feedback>
                      </div>
                      </Form.Group>
                    </div>
                  )}
                </div>
              </div>
            ) : null;
          }
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

               <div className="txx-fld as-select-input">
              <Form.Control
                as="select"
                multiple={
                  template.formName === "Equipment Downtime" &&
                  field.name === "Equipment Type"
                    ? true
                    : false
                }
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices?.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "master_choice":
          return field?.isMultiselect ? (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld master-chioce-box">
              {/* Custom Dropdown with checkboxes styled like a full-width select component */}
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="w-100 d-flex justify-content-between align-items-center"
                  style={{
                    textAlign: "left",
                    borderColor: "#ced4da",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    boxShadow: "none",
                    overflowX: "scroll",
                  }}
                >
                  {data[field.name]?.length > 0
                    ? `Selected: ${data?.[field?.name]?.join(", ")}`
                    : "Select options"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="w-100"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {field.choices.map((choice, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={choice}
                      value={choice}
                      checked={data?.[field.name]?.includes(choice)}
                      onChange={(e) =>
                        handleCheckboxChange(e, choice, field.name)
                      }
                      className="mx-3"
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          ) : (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

               <div className="txx-fld master-chioce-box">
              <Form.Control
                as="select"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices?.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "text":
          if (field.name === "GPS coordinates of the farm") {
            return (
              <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>

                 <div className="txx-fld">                 
                <Form.Control
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Button
                  variant="outline-secondary"
                  onClick={(e) => handlePosition(field.name)}
                >
                  Get my GPS
                </Button>
                </div>
              </Form.Group>
            );
          } else {
            return (
              <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <div className="txx-fld"> 
                <Form.Control
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
                </div>
              </Form.Group>
            );
          }

        case "text-large":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld">       
              <Form.Control
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "boolean":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Check
                type="checkbox"
                value={data[field.name]}
                label={field.name}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "time":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

               <div className="txx-fld"> 
              <Form.Control
                type="time"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "master_value":
          return (
            <Form.Group className="modal-fld" key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld "> 
              <Form.Control
                disabled={true}
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        default:
          return null;
      }
    }
  };

  const handleClose = () => {
    setErrors({});
    onHide();
    setEnabledDailyFlow(false);
    setShowConfirmation(false);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={showConfirmation ? "confirm-popup" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {template && template?.map((field) => renderFields(field))}
          </Form>
          <Button variant="primary" className="save-btn" onClick={handleSubmit}>
            Edit
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>          
        </Modal.Footer> */}
      </Modal>
      {showConfirmation && (
        <Modal show={showConfirmation} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{confirmMessage}</p>
            <Button variant="primary" className="save-btn" onClick={handleConfirmation}>
              OK
            </Button>
          </Modal.Body>
          {/* <Modal.Footer>
           
          </Modal.Footer> */}
        </Modal>
      )}
    </div>
  );
};

export default DataEditModal;
