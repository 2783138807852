import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { MultiSelect } from 'react-multi-select-component'
import { AiOutlineDelete } from 'react-icons/ai'
import { FaArrowLeft } from 'react-icons/fa6'
import { FiEdit } from 'react-icons/fi'
import { IoCloudDownloadOutline } from "react-icons/io5"
import { CSVLink } from "react-csv"
import ReactPaginate from 'react-paginate'

import AppNavBar from '../../components/navbar'
import AppFooter from '../../components/footer'

import AnomalyEditModal from '../../components/anomalyEdit'
import MessagePopup from '../../components/messagePopup'

import { getAnomalyList } from '../../hooks/anomalyList'

import './style.css'

export const AnomalyListPage = (token) => {
    const { id } = useParams()

    // response from backend
    const [formName, setFormName] = useState('')
    const [template, setTemplate] = useState([])
    const [formData, setFormData] = useState([])
    const [canEditAndDelete, setCanEditAndDelete] = useState(true)

    // message window content
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState('')

    const updateMessage = (type, content) => {
        setMessageType(type)
        setMessage(content)
    }

    // the data to show in the frontend
    const [visibleData, setVisibleData] = useState([])

    // anomaly status
    const anomalyStatus = [
        { label: 'Stopped', value: 'Stopped', boolLabel: false }, 
        { label: 'Ongoing', value: 'Ongoing', boolLabel: true }
    ]
    const [selectedAnomalyStatus, setSelectedAnomalyStatus] = useState([])

    // feedback status
    const feedbackStatus = [
        { label: 'Has Feedback', value: 'Has Feedback', boolLabel: true }, 
        { label: 'Awaiting Feedback', value: 'Awaiting Feedback', boolLabel: false }
    ]
    const [selectedFeedbackStatus, setSelectedFeedbackStatus] = useState([])

    // form pagination
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [pageCount, setPageCount] = useState(1)


    const filterData = (data) => {
        const anomalyStatusLabels = selectedAnomalyStatus.map((item) => item['label'])
        const feedbackStatusLabels = selectedFeedbackStatus.map((item) => item['label'])
        return data.filter((item) => {
            const includesStopped = (anomalyStatusLabels.includes('Stopped')) && (!item.is_in_5_min)
            const includesOngoing = (anomalyStatusLabels.includes('Ongoing')) && item.is_in_5_min
            const anomalyStatusResult = includesStopped || includesOngoing

            const includesHasFB = (feedbackStatusLabels.includes('Has Feedback')) && (item.user)
            const includesAwaitFB = (feedbackStatusLabels.includes('Awaiting Feedback')) && (!item.user)
            const feedbackStatusResult = includesHasFB || includesAwaitFB

            if (anomalyStatusResult && feedbackStatusResult) {
                return item
            }
        })
    }

    const getFormInfo = () => {
        getAnomalyList(id, '30d', page, pageSize, true)
            .then((response) => {
                // store the info
                setFormName(response.data.formName)
                setTemplate(response.data.template)
                setFormData(response.data.data)
                setCanEditAndDelete(response.data.canEditAndDelete)
                setPageCount(response.data.pageCount)
                
                setSelectedAnomalyStatus(anomalyStatus)
                setSelectedFeedbackStatus(feedbackStatus)
                
                setVisibleData(response.data.data)
            })
            .catch((error) => {
                updateMessage('error', 'Failed to get the anomaly list!')
            })
    }

    useEffect(() => {
        getFormInfo()
    }, [])

    // change the layout by page width
    const [isWideEnough, setIsWideEnough] = useState(window.innerWidth > 1000)
    useEffect(() => {
        const handleResize = () => {
            setIsWideEnough(window.innerWidth > 1000)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // handle data edit/delete modal
    const [dataInfoToModify, setDataInfoToModify] = useState(null)

    const [showAnomalyEditModal, setShowAnomalyEditModal] = useState(false)
    const handleAnomalyEditModal = (status, dataInfo) => {
        if (status) {
            // pass the info to modal
            setDataInfoToModify(dataInfo)
            setShowAnomalyEditModal(status)
        } else {
            setDataInfoToModify(null)
            setShowAnomalyEditModal(status)
        }
    }

    // timerange dropdown
    const [timeRange, setTimeRange] = useState('30d')
    const handleTimeRange = (event) => {
        setTimeRange(event.target.value)
    }
    const handleAnomalyStatusSelection = (value) => {
        setSelectedAnomalyStatus(value)
    }
    const handleFeedbackStatusSelection = (value) => {
        setSelectedFeedbackStatus(value)
    }

    // update data when filters changes
    useEffect(() => {
        getAnomalyList(id, timeRange, page, pageSize)
            .then((response) => {

                // store the info
                setFormData(response.data.data)
                setPageCount(response.data.pageCount)
                
                setVisibleData(filterData(response.data.data))

            })
            .catch((error) => {
                updateMessage('error', 'Failed to get the anomaly list!')
            })
    }, [timeRange, page, pageSize])
    useEffect(() => {
        setVisibleData(filterData(formData))
    }, [selectedAnomalyStatus, selectedFeedbackStatus])

    // date/time formatter
    const timeFormatter = (time) => {
        const date = new Date(time)
        return date.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })
    }

    const pageNumberDropDown = (pageNumber) => {
        return Array.from({ length: pageNumber }, (_, index) => (
            <option value={index + 1}>{index + 1}</option>
        ))
    }

    if (isWideEnough) {
        return (
            <>
                <AppNavBar />
                <div className="table-ctn">
                    <div className="table-title-ctn">
                        <a href="/" className="back-button">
                            <FaArrowLeft /> Back
                        </a>
                        <h2 className="table-title">{formName}</h2>
                        <div></div>
                    </div>

                    <div className="tool-container">
                        <div className="half-tool-container">
                            <div>
                                <label className="tool-name">Range of {formName === 'Equipment Maintenance' ? 'Start Date' : 'Time of Data Entry'}:</label>
                                <select value={timeRange} onChange={handleTimeRange}>
                                    <option value="30d">Last 30 days</option>
                                    <option value="60d">Last 60 days</option>
                                    <option value="90d">Last 90 days</option>
                                    <option value="6m">Last 6 months</option>
                                    <option value="1y">Last 1 year</option>
                                    <option value="2y">Last 2 years</option>
                                    <option value="5y">Last 5 years</option>
                                    <option value="all">All</option>
                                </select>
                            </div>

                            <CSVLink data={visibleData} className='download-btn'>
                                <IoCloudDownloadOutline />
                            </CSVLink>
                        </div>

                        <div className="half-tool-container">
                            <MultiSelect 
                                options={anomalyStatus} 
                                value={selectedAnomalyStatus} 
                                onChange={handleAnomalyStatusSelection} 
                                labelledBy="Select" 
                                className="mutliselect" 
                                overrideStrings={{
                                    allItemsAreSelected: "Anomaly Status: All",
                                }}
                            />
                        
                            <MultiSelect 
                                options={feedbackStatus} 
                                value={selectedFeedbackStatus} 
                                onChange={handleFeedbackStatusSelection} 
                                labelledBy="Select" 
                                className="mutliselect"
                                overrideStrings={{
                                    allItemsAreSelected: "Feedback Status: All",
                                }}
                            />
                        </div>
                    </div>

                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="table-responsive">
                                    <Table responsive striped borderless hover className="table" size="sm">
                                        <thead>
                                            <tr>
                                                {template.map((field) => {
                                                    return (
                                                        <th className="col-names text-center">{field.name}</th>
                                                    )
                                                })}
                                                {canEditAndDelete && <th className="col-names text-center">Edit</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {visibleData.map((data) => (
                                                <tr>
                                                    {template.map((field) => {
                                                        return (
                                                            <td className="text-center">{data[field.var]}</td>
                                                        )
                                                    })}
                                                    {canEditAndDelete && (
                                                        <td className="text-center">
                                                            <button className="table-btn" onClick={() => handleAnomalyEditModal(true, data)}>
                                                                <FiEdit />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                </div>

                <div className="pagination-ctn">
                    <div></div>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={(event) => setPage(event.selected)}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                    <div className='pagesize-ctn'>
                      <label htmlFor="pageSize">Showing</label>
                      <input
                        type="number"
                        id="pageSize"
                        value={pageSize}
                        onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
                        min={1}
                        className='pagesize-box'
                      />
                      <label htmlFor="pageSize">items per page</label>
                    </div>
                </div>

                <AnomalyEditModal show={showAnomalyEditModal} onHide={() => handleAnomalyEditModal(false, null)} dataInfo={dataInfoToModify} formId={id} template={template} getFormInfo={getFormInfo} updateMessage={updateMessage} />
                <MessagePopup message={message} messageType={messageType} onClose={() => setMessage('')} />

                <AppFooter />
            </>
        )
    } else {
        return (
            <>
                <AppNavBar needBack={true} />
                <div className="table-ctn">
                    <h2 className="table-title">{formName}</h2>

                    <div className="tool-container">
                        <div>
                            <label className="tool-name">Range of {formName === 'Equipment Maintenance' ? 'Start Date' : 'Time of Data Entry'}:</label>
                            <select value={timeRange} onChange={handleTimeRange}>
                                <option value="30d">Last 30 days</option>
                                <option value="60d">Last 60 days</option>
                                <option value="90d">Last 90 days</option>
                                <option value="6m">Last 6 months</option>
                                <option value="1y">Last 1 year</option>
                                <option value="2y">Last 2 years</option>
                                <option value="5y">Last 5 years</option>
                                <option value="all">All</option>
                            </select>
                        </div>

                        <CSVLink data={visibleData} className='download-btn'>
                            <IoCloudDownloadOutline />
                        </CSVLink>
                    </div>

                    <div className="tool-container">
                        <MultiSelect 
                            options={anomalyStatus} 
                            value={selectedAnomalyStatus} 
                            onChange={handleAnomalyStatusSelection} 
                            labelledBy="Select" 
                            className="mutliselect" 
                            overrideStrings={{
                                allItemsAreSelected: "Anomaly Status: All",
                            }}
                        />
                    
                        <MultiSelect 
                            options={feedbackStatus} 
                            value={selectedFeedbackStatus} 
                            onChange={handleFeedbackStatusSelection} 
                            labelledBy="Select" 
                            className="mutliselect"
                            overrideStrings={{
                                allItemsAreSelected: "Feedback Status: All",
                            }}
                        />
                    </div>

                    <Container fluid>
                        {visibleData.map((data, index) => (
                            <Row key={index} className={index % 2 === 0 ? 'row-even' : 'row-odd'}>
                                {template.map((field) => {
                                    return (
                                        <Row>
                                            <Col className="field-names">{field.name}</Col>
                                            <Col className="text-center">{data[field.var]}</Col>
                                        </Row>
                                    )
                                })}
                                {canEditAndDelete && (
                                    <Row>
                                        <Col className="field-names">Edit</Col>
                                        <Col className="text-center">
                                            <button className="table-btn" onClick={() => handleAnomalyEditModal(true, data)}>
                                                <FiEdit />
                                            </button>
                                        </Col>
                                    </Row>
                                )}
                            </Row>
                        ))}
                    </Container>
                </div>

                <div className='pagesize-ctn'>
                    <label htmlFor="pageNumber">Current Page: </label>
                    <select id="pageNumber"  onChange={(e) => setPage(e.target.value)}>
                        {pageNumberDropDown(pageCount)}
                    </select>
                </div>
                <div className='pagesize-ctn'>
                    <label htmlFor="pageSize">Showing</label>
                    <input
                        type="number"
                        id="pageSize"
                        value={pageSize}
                        onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
                        min={1}
                        className='pagesize-box'
                    />
                    <label htmlFor="pageSize">items per page</label>
                </div>

                <AnomalyEditModal show={showAnomalyEditModal} onHide={() => handleAnomalyEditModal(false, null)} dataInfo={dataInfoToModify} formId={id} template={template} getFormInfo={getFormInfo} updateMessage={updateMessage} />
                <MessagePopup message={message} messageType={messageType} onClose={() => setMessage('')} />

                <AppFooter />
            </>
        )
    }
}
