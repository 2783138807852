import axios from "axios";
import config from "../config";
import api from '../interceptors/axios'


async function resetPassword(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post(
      `/api/resetPassword`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.statusText || "An error occurred");
  }
}

async function forgotPassword(data) {
  try {
    const response = await api.post(
      `/api/forgetPassword`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.message ||
    error.response?.statusText ||
    "An error occurred"
  }
}

async function resetPasswordRequest(data) {
  try {
    const response = await api.post(
      `/api/forgetPasswordRequest`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        error.response?.statusText ||
        "An error occurred"
    );
  }
}

async function verifyEmail(token) {
  try {
    const response = await api.post(
      `/api/verifyEmail`,
      { token }, // Send token as part of the request body
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
      return response.data;
  } catch (error) {
    throw new Error(error.response?.data || error.response?.statusText || "An error occurred");
  }
}

export default verifyEmail;


export { resetPassword, forgotPassword, resetPasswordRequest, verifyEmail };
