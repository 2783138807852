import React, { useState, useEffect } from "react";
import { Toast, ToastContainer, Button } from "react-bootstrap";

const MessagePopup = ({
  message,
  messageType,
  onClose,
  requireConfirmation = false,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
    }
  }, [message, messageType]);

  const closePopup = () => {
    onClose();
    setShow(false);
  };

  let messageHeading = "";
  let messageClassName = "";

  switch (messageType) {
    case "success":
      messageHeading = "Success";
      messageClassName = "text-success";
      break;

    case "warning":
      messageHeading = "warning";
      messageClassName = "text-warning";
      break;

    default:
      messageHeading = "Error";
      messageClassName = "text-danger";
      break;
  }

  return (
    <ToastContainer
      position={window.innerWidth > 1024 ? "top-start" : "bottom-center"}
    >
      <Toast
        show={show}
        delay={5000}
        onClose={requireConfirmation ? null : closePopup}
        autohide={!requireConfirmation}
      >
        <Toast.Header>
          <strong className={`me-auto ${messageClassName}`}>
            {/* {messageType === "error" ? "Error" : "Success"} */}
            {messageHeading}
          </strong>
        </Toast.Header>
        {/* <Toast.Body>
          {message}
          {requireConfirmation && (
            <div className="mt-2 text-end">
              <Button variant="primary" onClick={closePopup}>OK</Button>
            </div>
          )}
        </Toast.Body> */}
        <Toast.Body>
  {String(message).includes('\n')
    ? String(message).split('\n').map((line, index) => (
        <div 
          key={index} 
          style={{ marginBottom: index < String(message).split('\n').length - 1 ? "1rem" : "0" }}
        >
          {line}
        </div>
      ))
    : <div>{message}</div>}
  {requireConfirmation && (
    <div className="mt-2 text-end">
      <Button variant="primary" onClick={closePopup}>OK</Button>
    </div>
  )}
</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default MessagePopup;
