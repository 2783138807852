import React, { useEffect, useState } from "react";
import { verifyMobile } from "../../hooks/verifyMobile";
import "./style.css";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MobileVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [error, setError] = useState('');
    const [variant, setVariant] = useState('');
    const [message, setMessage] = useState(null);
    const [userId, setUserId] = useState(null);

    const navigate = useNavigate(); // Use navigate for redirection

    // Extract token from URL path
    const idToken = window.location.pathname;
    const token = idToken.split("/")[2]; // Adjust this based on your URL structure

  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    const value = element.value;
  
    if (element.value === "" && element.previousSibling) {
      // Move to the previous input field when backspace is pressed on an empty input
      const updatedOtp = [...otp];
      updatedOtp[index] = ""; // Clear the current input
      setOtp(updatedOtp);
      element.previousSibling.focus();
    } else if (/\d/.test(value) || value === "") {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
  
      // Move to next input field
      if (value && element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");
    const otpLength = paste.length;
  
    if (otpLength <= otp.length) {
      const updatedOtp = [...otp];
      for (let i = 0; i < otpLength; i++) {
        updatedOtp[i] = paste[i];
      }
      setOtp(updatedOtp);
    }

    // Move focus to the last input box
    const lastInput = document.querySelectorAll('.otp-box');
    lastInput[lastInput.length - 1].focus();
  };

  const handleKeyDown = (event, index) => {
    const key = event.key;
    const element = event.target;
  
    if (key === "Backspace" && !otp[index] && element.previousSibling) {
      // Move to the previous input when pressing backspace on an empty box
      const updatedOtp = [...otp];
      updatedOtp[index - 1] = ""; // Clear the previous box
      setOtp(updatedOtp);
      element.previousSibling.focus();
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");
  const isOtpEmpty = otp.every((digit) => digit === "");

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
  }, []);

  const handleSubmit = () => {
    const otpValue = otp.join("");

    verifyMobile({
        "userId": userId,
        "token": token,
        "otp": otpValue
    })
    .then((res) => {
        setVerificationStatus('success');
        setVariant('success');
        setMessage(res.message);
        setTimeout(() => {
          navigate('/'); // Redirect to login page after a delay
      }, 3000); // 3 seconds delay
    })
    .catch((error) => {
        setVerificationStatus('error');
        setError(`[${error.message}] Failed to verify mobile!`);
        setVariant('danger');
        setMessage(
            `${error.message}`
          );
        setOtp(new Array(6).fill(""));
    })
  };

  const handleClear = () => {
    setOtp(new Array(6).fill(""));
    setVerificationStatus(null);
    setError('');
    setMessage(null);
    setVariant('');
  };


  return (
    <div className="main-container">
        <div className="verification-content" style={{ width: '100%' }}>
              <div className="otp-container">
              <div className="otp-card">
                {message && (
                  <Alert variant={variant} className="custom-alert">
                    {message}
                  </Alert>
                )}
                <h1 className="otp-title">Verify Your WhatsApp Number</h1>
                <p className="otp-description">
                  OTP has been sent to your WhatsApp number. Please verify it. 
                </p>
                <div className="otp-inputs">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="tel" 
                      maxLength="1"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      value={value}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()} // Select text on focus
                      onPaste={handlePaste} // Trigger handlePaste on paste
                      className="otp-box"
                    />
                  ))}
                </div>
                <div className="otp-buttons">
                  <button onClick={handleSubmit} className="otp-submit-button otp-button" disabled={!isOtpComplete}>
                    Verify
                  </button>
                  <button onClick={handleClear} className="otp-clear-button otp-button" disabled={isOtpEmpty}>
                    Clear
                  </button>
                </div>
                <p className="otp-footer-text">
                  If you did not receive the OTP, Please contact Admin.
                </p>
              </div>
            </div>
        </div>
    </div>
  );
};

export default MobileVerification;