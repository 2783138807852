import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { deleteUser } from "../../hooks/userDelete.js";
import "./style.css";

const UserDeleteModal = ({
  show,
  onHide,
  username,
  userId,
  getOrgInfo,
  updateMessage,
}) => {
  const handleSubmit = () => {
    deleteUser({ user_id: userId })
      .then((response) => {
        // Close the modal
        onHide();
        getOrgInfo();
        if (response && response.status == "Success") {
          const successMsg = `${response.message}`;
          updateMessage("success", successMsg, {
            html: true,
            requireConfirmation: true,
          });
        }
      })
      .catch((error) => {
        updateMessage("error", `[${error.message}] Failed to delete user!`);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Are you sure you want to delete the user "{username}"?</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDeleteModal;
