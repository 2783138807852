import axios from "axios";
import config from "../config";
import api from '../interceptors/axios'

async function editData(data, form_id, data_id) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.put(
      `/api/form/${form_id}/data/${data_id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error(
      error.response?.data || error.response?.statusText || "An error occurred"
    );
  }
}

export { editData };
