import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { isEmpty, isJWT } from "validator";
import "./index.css"; // Import the custom CSS file
import { forgotPassword } from "../../hooks/resetPassword";
import MessagePopup from "../messagePopup";
import { useNavigate } from "react-router-dom";
import { Reminder } from "../../utils/validation";

const ForgotPassword = () => {
  const idToken = window.location.pathname;
  const splitRoute = idToken.split("/");
  const router = useNavigate();
  const token = splitRoute[2];
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [variant, setVariant] = useState("success");
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errorStatus = { ...error };

    if (
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/
      )
    ) {
      if (isEmpty(password, { ignore_whitespace: true })) {
        errorStatus.password = "New Password is required.";
      } else if (password.length < 8) {
        errorStatus.password = "New Password must have at least 8 characters.";
      } else if (!password.match(/[a-z]/)) {
        errorStatus.password =
          "New Password must have at least 1 lowercase letter.";
      } else if (!password.match(/[A-Z]/)) {
        errorStatus.password =
          "New Password must have at least 1 uppercase letter.";
      } else if (!password.match(/\d/)) {
        errorStatus.password = "New Password must have at least 1 number.";
      } else if (!password.match(/[a-zA-Z]/)) {
        errorStatus.password = "New Password must have at least 1 letter.";
      } else if (!password.match(/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]/)) {
        errorStatus.password =
          "New Password must have at least 1 special character.";
      }
    }

    if (isEmpty(confirmPassword)) {
      errorStatus.confirmPassword = "Confirm Password is required.";
    } else if (password !== confirmPassword && password) {
      errorStatus.confirmPassword =
        "The new password and confirm password do not match.";
    }

    if (Object.values(errorStatus).some((value) => value !== "")) {
      setError(() => errorStatus);
      return;
    }

    forgotPassword({
      verification_token: token,
      new_password: password,
      confirm_new_password: confirmPassword,
    })
      .then((res) => {
        setVariant("success");
        updateMessage("success", res.message);
        setTimeout(() => {
          localStorage.clear(); // Clear local storage
          router("/");
        }, 2000);
      })
      .catch((error) => {
        setVariant("danger");
        updateMessage("error", error);
        setPassword("");
        setConfirmPassword("");
      });
  };

  if (!isJWT(token)) {
    return (
      <Container>
        <h2 className="forgot-password-title">Page not found</h2>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="display-screen justify-content-md-center">
        <Col md={6}>
          <div className="forgot-password-container">
            {message && (
              <Alert variant={variant} className="custom-alert">
                {message}
              </Alert>
            )}
            <MessagePopup
              message={message}
              messageType={messageType}
              onClose={() => setMessage("")}
            />
            <h2 className="forgot-password-title">Reset Password</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>
                  New Password <span className="text-danger">*</span>
                </Form.Label>
                <div className="email-fld">
                  <Form.Control
                    isInvalid={error.password === "" ? false : true}
                    required
                    type="password"
                    placeholder="Enter New Password"
                    value={password}
                    onChange={(e) => {
                      setError({ ...error, password: "" });
                      setPassword(e.target.value);
                    }}
                  />
                  {!(error.password || error.confirmPassword) && (
                    <Form.Text className="text-muted">
                      {Reminder("password")}
                    </Form.Text>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {error.password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group
                style={{
                  marginTop: "10px",
                }}
              >
                <Form.Label>
                  Confirm Password <span className="text-danger">*</span>
                </Form.Label>
                <div className="email-fld">
                  <Form.Control
                    isInvalid={error.confirmPassword === "" ? false : true}
                    required
                    type="password"
                    placeholder="Enter Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setError({ ...error, confirmPassword: "" });
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.confirmPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Form>
            <Button
              variant="primary"
              // type="submit"
              onClick={handleSubmit}
              className="forgot-password-btn mt-3"
            >
              Submit
            </Button>
            <div className="mt-3">
              <a href="/" className="back-to-home">
                Back to home
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
